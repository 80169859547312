import React, { useEffect, useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookieConsentBanner = () => {

  const [hubspotScriptInjected, setHubspotScriptInjected] = useState(false);

  // Function to dynamically inject HubSpot tracking script
  const injectHubSpotScript = () => {
    if (!hubspotScriptInjected) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hs-script-loader";
      script.async = true;
      script.defer = true;
      script.src = "//js.hs-scripts.com/44141902.js";
      document.body.appendChild(script);
      setHubspotScriptInjected(true); // Avoid reinjecting the script
      console.log("HubSpot script injected");
    }
  };

  const handleAccept = () => {
    Cookies.set("gatsby-gdpr-google-analytics", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-google-tagmanager", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-linked-in", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-hubspot", "true", { path: "/" });

    // Reload the page after the user accepts the cookies
    window.location.reload();
    
    console.log("User accepted all cookies.");
  };

  const handleDecline = () => {
    Cookies.set("gatsby-gdpr-google-analytics", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-google-tagmanager", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-linked-in", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-hubspot", "false", { path: "/" });
    
    console.log("User declined all cookies.");
  };

  useEffect(() => {
    if (Cookies.get("gatsby-gdpr-google-analytics") === "true") {
      console.log("Google Analytics is active");
      // Initialize Google Analytics script if necessary
      //window.ga('create', 'UA-XXXXXXXXX-X', 'auto');
      //window.ga('send', 'pageview');
    }

    if (Cookies.get("gatsby-gdpr-google-tagmanager") === "true") {
      console.log("Google Tag Manager is active");
      // Initialize GTM script if necessary
      //window.dataLayer = window.dataLayer || []; window.dataLayer.push({ 'event': 'gtm.js' });
    }

    if (Cookies.get("gatsby-gdpr-hubspot") === "true") {
      // Inject HubSpot script if user accepts cookies
      injectHubSpotScript();

      // load HubSpot tracking script immediately after consent
      window._hsq = window._hsq || [];
      window._hsq.push(['trackPageView']);
      console.log("Hubspot is active");
    }

  }, []);

  return (
    <CookieConsent
      onAccept={handleAccept}
      onDecline={handleDecline}
      enableDeclineButton
      buttonText="Accept All Cookies"
      declineButtonText="Reject All"
      style={{
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '0px',
        padding: '15px',
        fontSize: '14px',
        zIndex: '1000',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column', // Stack content and buttons
        flex: '1 0 50px!important', // Decrease flex-basis to 50px
        alignItems: 'center', // Center the content and buttons
      }}
      buttonWrapperClasses="button-container"
      buttonStyle={{
        backgroundColor: '#f1c40f',
        border: 'none',
        color: 'black',
        padding: '15px 10px',
        cursor: 'pointer',
        borderRadius: '0px',
        fontSize: '14px',
        margin: '10px 50px',
      }}
      declineButtonStyle={{
        backgroundColor: '#f1c40f',
        border: 'none',
        color: 'black',
        padding: '15px 10px',
        cursor: 'pointer',
        borderRadius: '0px',
        fontSize: '14px',
        margin: '10px 50px',
      }}
    >
      <div>
        <h4>We use cookies</h4>
        <p style={{ marginTop: "2.4rem", fontSize: "1.4rem", lineHeight: "1.8rem", textAlign: "center" }}>
          At Fulcrum GT, we're dedicated to empowering your firm journey. That's why we use cookies to enhance your experience on our site, optimize your interactions, offer social media features, and personalize our marketing efforts. We also use cookies to deliver content and ads tailored to your interest, including on third-party websites. For more information, visit our <a href="/privacy-policy" style={{ color: "#f1c40f", textDecoration: "none" }}>Privacy Policy</a>.
        </p>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
