module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1,"once":true,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FulcrumGT","short_name":"FulcrumGT","start_url":"/","background_color":"#e5f3fb","theme_color":"#e5f3fb","display":"standalone","icon":"./src/assets/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"40a50d05a06726101468242892139ac4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-7NRBTFNXJC","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"cookieFlags":"SameSite=Lax; Secure"},"googleTagManager":{"trackingId":"GTM-NP6NW3N","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"linkedin":{"trackingId":"4221652","cookieName":"gatsby-gdpr-linked-in"},"hubspot":{"trackingId":"44141902","cookieName":"gatsby-gdpr-hubspot"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
